<template>
    <div class="single-gender">
        <div class="single-icon">
            <icon
                :name="platform === 'facebook' ? 'meta' : platform"
                color="#8F9EA6"
                size="25" />
        </div>
        <div class="single-radios">
            <v-radio-group
                v-model="gender"
                row>
                <v-radio
                    class="single-radio"
                    label="All"
                    value="ALL"
                    color="#00A3EA" />
                <v-radio
                    :disabled="isCreditCampaign"
                    class="single-radio"
                    label="Female"
                    value="FEMALE"
                    color="#00A3EA" />
                <v-radio
                    :disabled="isCreditCampaign"
                    class="single-radio"
                    label="Male"
                    value="MALE"
                    color="#00A3EA" />
            </v-radio-group>
        </div>
        <p
            v-if="isCreditCampaign"
            class="credit-campaign-text">
            Credit Ads are not eligible to be customized by Gender
        </p>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapGetters } from 'vuex';
export default {
    components: {
        Icon
    },
    props: {
        platform: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            getState: 'adDeployment/getState'
        }),
        isFacebook() {
            return this.platform === 'facebook';
        },
        isCreditCampaign() {
            return this.isFacebook && this.getState.campaign_selection.all_platforms.ad_category === 'credit';
        },
        gender: {
            get() {
                return this.$store.state.adDeployment.audience.platform_specific[this.platform].gender;
            },
            set(value) {
                this.$store.commit('adDeployment/setPlatformGender', { platform: this.platform, gender: value });
            }
        }
    }
};
</script>


<style lang="scss" scoped>
.single-gender {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.single-icon{
  margin-right: 30px;
  margin-bottom: 5px;
}
.single-radio{
  display: flex;
}
.credit-campaign-text {
  margin-top: -5px;
  font-size: 12px;
  color: #9B9B9B;
  line-break: anywhere;
  font-style: italic;
}
</style>
