<template>
    <!-- gender -->
    <div class="audience-wrapper">
        <div class="audience">
            <div class="audience__top">
                <div class="audience__top-main">
                    <div class="audience__holder">
                        <div class="audience__title">
                            GENDER
                        </div>
                    </div>
                    <div class="mr-4">
                        <v-radio-group
                            v-model="genderCustomizedSelected"
                            row>
                            <v-radio
                                class="single-radio"
                                label="All"
                                :value="false"
                                color="#00A3EA" />
                            <v-radio
                                class="single-radio"
                                label="Customize"
                                :value="true"
                                color="#00A3EA" />
                        </v-radio-group>
                    </div>
                    <div class="audience__desc">
                        target by gender
                    </div>
                </div>
                <div
                    class="audience__arrow"
                    @click="(genderCustomizationOpen = !genderCustomizationOpen)">
                    <span>
                        <icon
                            :class="genderCustomizationOpen ? 'up' : 'down'"
                            name="arrow-left"
                            color="#8F9EA6"
                            size="25" />
                    </span>
                </div>
            </div>
            <div
                v-if="genderCustomizationOpen"
                class="audience__content">
                <div
                    v-for="(platform, i) in selectedChannels"
                    :key="i">
                    <gender-selector :platform="platform" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import GenderSelector from '@/components/ad-deployment/steps/Audiences/Gender/GenderSelector';
import { mapState } from 'vuex';
export default {
    name: 'Genders',
    components: {
        Icon,
        GenderSelector,
    },
    data() {
        return {
            genderCustomizedSelected: false,
            genderCustomizationOpen: false,
        };
    },
    computed: {
        ...mapState({
            audienceStepData: state => state.adDeployment.audience.platform_specific,
            selectedChannels: state => state.adDeployment.platforms
        }),
        genderUsingDefaults() {
            return this.selectedChannels.every(platform => {
                return this.audienceStepData[platform].gender === 'ALL';
            });
        },
    },
    watch: {
        genderUsingDefaults: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.genderCustomizedSelected = false;
                } else {
                    this.genderCustomizedSelected = true;
                }
            }
        },
        genderCustomizedSelected: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.genderCustomizationOpen = true;
                } else {
                    this.setGendersToDefault();
                }
            }
        }
    },
    methods: {
        setGendersToDefault() {
            return this.selectedChannels.forEach(platform => {
                this.$store.commit('adDeployment/setPlatformGender', { platform, gender: 'ALL' });
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.audience-wrapper{
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;
    margin-right: 45px;
    margin-bottom: 30px;
}
.audience{
    &__top{
        border-bottom: 1px solid #DFDFE0;
        display: flex;
    }
    &__top-main{
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 80px);
    }
    &__arrow{
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-left: 1px solid #DFDFE0;
        cursor: pointer;
        & > span{
            display: inline-block;
        }
    }
    &__holder{
        display: flex;
        align-items: center;
    }
    &__title{
        font-weight: 700;
    }
    &__desc{
        color: #03A2EA;
        font-style: italic;
        display: flex;
        align-items: center;
    }
    &__content{
        padding: 25px 45px;
    }
}

.up{
    transform: rotate(-90deg);
}
.down{
    transform: rotate(90deg);
}
</style>