<template>
    <div>
        <div class="single-audience mb-4">
            <div class="single-icon">
                <icon
                    :name="channel === 'facebook' ? 'meta' : channel"
                    color="#8F9EA6"
                    size="25" />
            </div>
            <div class="fields">
                <div class="field">
                    <v-autocomplete
                        v-model="selectedAudiences"
                        :items="availableAudiences"
                        :return-object="true"
                        item-text="name"
                        item-value="id"
                        label="Select..."
                        :loading="loading"
                        chips
                        multiple
                        required
                        class="styled-field ad-deployment-combo">
                        <template #item="{item}">
                            <div
                                v-if="item.available"
                                class="option">
                                <div class="status">
                                    <div />
                                </div>
                                <div class="option-text">
                                    <div>{{ item.name }}</div>
                                    <div class="option-desc">
                                        {{ item.id }} - {{ item.description }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="option"
                                @click.stop.prevent>
                                <div class="not-available">
                                    <div class="status">
                                        <div />
                                    </div>
                                    <div class="option-text">
                                        <div>{{ item.name }}</div>
                                        <div class="option-desc">
                                            {{ item.id }} - {{ item.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :selected="selected"
                                small>
                                <span class="pr-2">
                                    {{ item.name }}
                                </span>
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)">
                                    close
                                </v-icon>
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </div>
                <div class="icons">
                    <action-button
                        size="24"
                        icon="outbound-user"
                        target="_blank"
                        :href="outboundLink">
                        <span class="nowrap">New Audience</span>
                    </action-button>
                    <action-button
                        class="ml-4"
                        size="24"
                        icon="refresh"
                        @click="getAudiencesFromApi">
                        Refresh
                    </action-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import { mapState, mapGetters } from 'vuex';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';
export default {
    components: {
        Icon,
        ActionButton
    },
    props: {
        channel: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            availableAudiences: [],
        };
    },
    computed: {
        ...mapState({
            platformAudiencesInStore(state) {
                return state.adDeployment.audience.platform_specific[this.channel].existing_audiences;
            },
            currentDealer: state => state.dealer.currentDealer
        }),
        ...mapGetters([
           'dealerPlatforms'
        ]),
        selectedAudiences: {
            get() {
                return this.platformAudiencesInStore;
            },
            set(value) {
                this.$store.commit('adDeployment/setExistingAudiences', {
                    audiences: value,
                    channel: this.channel
                });
            }
        },
        outboundLink() {
            const newAudienceLinks = {
                [PLATFORM_FACEBOOK]: `https://business.facebook.com/adsmanager/audiences?act=${this.getChannelAdAccountId()}&business_id=${this.currentDealer.agency.facebook_business_id}&global_scope_id=${this.currentDealer.agency.facebook_business_id}&tool=AUDIENCES`,
                [PLATFORM_SNAPCHAT]: `https://ads.snapchat.com/${this.getChannelAdAccountId()}/audiences`,
                [PLATFORM_TIKTOK]: `https://ads.tiktok.com/i18n_dmp/adver/main?aadvid=${this.getChannelAdAccountId()}`,
                [PLATFORM_PINTEREST]: `https://ads.pinterest.com/advertiser/${this.getChannelAdAccountId()}/audiences/`,
            };

            return newAudienceLinks[this.channel];
        },
    },
    created() {
        this.getAudiencesFromApi();
    },
    methods: {
        async getAudiencesFromApi() {
            this.loading = true;
            // todo add try catch and catch error messages in the select input
            let response = await this.$apiRepository.getDealerPlatformCustomAudiences({ dealerId: this.currentDealer.id, platform: this.channel, adAccountId: this.getChannelAdAccountId() });

            // Facebook, Snapchat, and TikTok return data and Pinterest returns data.data due
            // to having pagination in the response. Ideally they would all return the same
            // format but this is the quickest fix for now
            const data = response?.data?.data ?? response?.data ?? [];

            this.availableAudiences = data.map(audience => {
                return {
                    id: audience.external_id,
                    name: audience.name,
                    description: audience.description,
                    available: audience.is_available
                };
            });

            this.loading = false;
        },
        getChannelAdAccountId() {
            let channel = this.dealerPlatforms.find(dp => dp.name === this.channel);

            let adAccountId = '';

            if(this.channel === 'facebook') {
                adAccountId = this.currentDealer.facebook_ad_account_annotations.data[0].facebook_ad_account_id.replace('act_', '');
            } else {
                let adAccount = this.currentDealer.ad_accounts.data.find(cd => cd.platform_id === channel.id);

                if(adAccount) {
                    adAccountId = adAccount.external_ad_account_id;
                }
            }

            return adAccountId;
        },
    },
};
</script>


<style lang="scss" scoped>
.single-audience{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.single-icon{
    margin-right: 30px;
    margin-top: 5px;
}
.fields{
    width: 100%;
    display: flex;
}
.field{
    width: 100%;
    margin-right: 30px;
}
.channel-field{
    width: 40%;
}
.option{
    display: flex;
    align-items: center;
    height: 70px;
    margin-left: -15px;
}
.option-desc{
    font-size: 12px;
    font-style: italic;
    color: #ABABAB;
}
.not-available{
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom:0;
  left:0;
  right: 0;
  padding:10px 0;
  .status > div{
    background: #FF3A00;
  }
}
.status{
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #3EBF3F;
    }
}
.add{
    color: #00A4EB;
    border: 1px solid #00A4EB;
    border-radius: 5px;
    padding: 2px 20px;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
    transition: 0.3s;
    &:hover{
        color:white;
        background: #00A4EB;
    }
}
.icons{
    display: flex;
    margin-right: -15px;
}
.loading{
    text-align: center;
    .action-button{
        transform: translateY(5px);
    }
}
.nowrap{
    white-space: nowrap;
}
</style>
<style>
.v-list__tile{
    height: auto;
    min-height: 48px;
}
.ad-deployment-combo .v-chip__content > span{
    overflow: hidden;
    max-width: 310px;
}
</style>