<template>
    <!-- age -->
    <div class="audience-wrapper">
        <div class="audience">
            <div class="audience__top">
                <div class="audience__top-main">
                    <div class="audience__holder">
                        <div class="audience__title">
                            AGE
                        </div>
                    </div>
                    <div class="ml-5">
                        <v-radio-group
                            v-model="ageCustomizedSelected"
                            row>
                            <v-radio
                                class="single-radio"
                                label="Default"
                                :value="false"
                                color="#00A3EA" />
                            <v-radio
                                class="single-radio"
                                label="Customize"
                                :value="true"
                                color="#00A3EA" />
                        </v-radio-group>
                    </div>
                    <div class="audience__desc">
                        target by age range
                    </div>
                </div>
                <div
                    class="audience__arrow"
                    @click="(ageCustomizationOpen = !ageCustomizationOpen)">
                    <span>
                        <icon
                            :class="ageCustomizationOpen ? 'up' : 'down'"
                            name="arrow-left"
                            color="#8F9EA6"
                            size="25" />
                    </span>
                </div>
            </div>
            <div
                v-if="ageCustomizationOpen"
                class="audience__content">
                <div
                    v-for="(platform, index) in selectedPlatforms"
                    :key="index">
                    <platform-age-selector
                        :platform="platform"
                        :ad-index="index"
                        :platform-min="platformDefaults[platform].min"
                        :platform-max="platformDefaults[platform].max" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import PlatformAgeSelector from '@/components/ad-deployment/steps/Audiences/AgeSelections/PlatformAgeSelector';
import { mapState } from 'vuex';

export default {
    name: 'AgeSelections',
    components: {
        Icon,
        PlatformAgeSelector
    },
    data() {
        return {
            ageCustomizedSelected: false,
            ageCustomizationOpen: false,
            platformDefaults: {
                facebook: {
                    min: {
                        name: '18',
                        value: 18,
                    },
                    max: {
                        name: '65+',
                        value: 65
                    }
                },
                snapchat: {
                    min: {
                        name: '18',
                        value: 18,
                    },
                    max: {
                        name: '50+',
                        value: 50
                    }
                },
                tiktok: {
                    min: {
                        name: '18',
                        value: 18,
                    },
                    max: {
                        name: '55+',
                        value: 55
                    }
                },
                pinterest: {
                    min: {
                        name: '18',
                        value: 18,
                    },
                    max: {
                        name: '65+',
                        value: 65
                    }
                },
            }
        };
    },
    computed: {
        ...mapState({
            audienceStepData: state => state.adDeployment.audience.platform_specific,
            selectedPlatforms: state => state.adDeployment.platforms
        }),
        agesUsingDefaults() {
            return this.selectedPlatforms.every(platform => {
                return this.audienceStepData?.[platform]?.ages?.min?.value === this.platformDefaults?.[platform]?.min?.value &&
                    this.audienceStepData?.[platform]?.ages?.max?.value === this.platformDefaults?.[platform]?.max?.value;
            });
        },
    },
    watch: {
        agesUsingDefaults: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.ageCustomizedSelected = false;
                } else {
                    this.ageCustomizedSelected = true;
                }
            }
        },
        ageCustomizedSelected: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.ageCustomizationOpen = true;
                } else {
                    this.setAgesToDefault();
                }
            }
        }
    },
    methods: {
        setAgesToDefault() {
            return this.selectedPlatforms.forEach(platform => {
                this.$store.commit('adDeployment/setPlatformAges', {
                    platform,
                    ages: this.platformDefaults[platform]
                });
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.audience-wrapper{
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;
    margin-right: 45px;
    margin-bottom: 30px;
}
.audience{
    &__top{
        border-bottom: 1px solid #DFDFE0;
        display: flex;
    }
    &__top-main{
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 80px);
    }
    &__arrow{
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-left: 1px solid #DFDFE0;
        cursor: pointer;
        & > span{
            display: inline-block;
        }
    }
    &__holder{
        display: flex;
        align-items: center;
    }
    &__title{
        font-weight: 700;
    }
    &__desc{
        color: #03A2EA;
        font-style: italic;
        display: flex;
        align-items: center;
    }
    &__content{
        padding: 25px 45px;
    }
}

.up{
    transform: rotate(-90deg);
}
.down{
    transform: rotate(90deg);
}
</style>