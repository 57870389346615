<template>
    <!-- Audience -->
    <div class="audience-wrapper">
        <div class="audience">
            <div class="audience__top">
                <div class="audience__top-main">
                    <div class="audience__holder">
                        <div class="audience__title">
                            EXISTING AUDIENCE
                        </div>
                    </div>
                    <div class="ml-3">
                        <v-radio-group
                            v-model="audienceCustomizedSelected"
                            row>
                            <v-radio
                                class="single-radio"
                                label="None"
                                :value="false"
                                color="#00A3EA" />
                            <v-radio
                                class="single-radio"
                                label="Customize"
                                :value="true"
                                color="#00A3EA" />
                        </v-radio-group>
                    </div>
                    <div class="audience__desc">
                        use existing platform audiences
                    </div>
                </div>
                <div
                    class="audience__arrow"
                    @click="(audienceCustomizationOpen = !audienceCustomizationOpen)">
                    <span>
                        <icon
                            :class="audienceCustomizationOpen ? 'up' : 'down'"
                            name="arrow-left"
                            color="#8F9EA6"
                            size="25" />
                    </span>
                </div>
            </div>
            <div
                v-if="audienceCustomizationOpen"
                class="audience__content">
                <div
                    v-for="(channel, i) in selectedChannels"
                    :key="i">
                    <audience-selector :channel="channel" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import AudienceSelector from '@/components/ad-deployment/steps/Audiences/ExistingAudiences/AudienceSelector.vue';
import { mapState } from 'vuex';
export default {
    name: 'Genders',
    components: {
        Icon,
        AudienceSelector,
    },
    data() {
        return {
            audienceCustomizedSelected: false,
            audienceCustomizationOpen: false,
        };
    },
    computed: {
        ...mapState({
            audienceStepData: state => state.adDeployment.audience.platform_specific,
            selectedChannels: state => state.adDeployment.platforms
        }),
        existingAudienceSelected() {
            return this.selectedChannels.some(platform => {
                return Boolean(this.audienceStepData[platform].existing_audiences.length);
            });
        }
    },
    watch: {
        existingAudienceSelected: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.audienceCustomizedSelected = true;
                } else {
                    this.audienceCustomizedSelected = false;
                }
            }
        },
        audienceCustomizedSelected: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.audienceCustomizationOpen = true;
                } else {
                    this.removeAllExistingAudiences();
                }
            }
        }
    },
    methods: {
        removeAllExistingAudiences() {
            return this.selectedChannels.forEach(platform => {
                this.$store.commit('adDeployment/setExistingAudiences', { audiences: [], channel: platform });
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.audience-wrapper{
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;
    margin-right: 45px;
    margin-bottom: 30px;
}
.audience{
    &__top{
        border-bottom: 1px solid #DFDFE0;
        display: flex;
    }
    &__top-main{
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 80px);
    }
    &__arrow{
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-left: 1px solid #DFDFE0;
        cursor: pointer;
        & > span{
            display: inline-block;
        }
    }
    &__holder{
        display: flex;
        align-items: center;
    }
    &__title{
        font-weight: 700;
    }
    &__desc{
        color: #03A2EA;
        font-style: italic;
        display: flex;
        align-items: center;
    }
    &__content{
        padding: 25px 45px;
    }
}

.up{
    transform: rotate(-90deg);
}
.down{
    transform: rotate(90deg);
}
</style>