<template>
    <div>
        <!-- gender completed -->
        <div class="completed">
            <div class="single-completed-wrapper">
                <div class="completed-title">
                    GENDER
                </div>
                <div
                    v-for="(channel, index) in selectedChannels"
                    :key="index"
                    class="single-completed">
                    <div class="icon">
                        <icon
                            :name="channel === 'facebook' ? 'meta' : channel"
                            color="#8F9EA6"
                            size="25" />
                    </div>
                    <span class="single-name">{{ audienceStepData[channel].gender || 'N/A' }}</span>
                </div>
            </div>
            <edit-button step="audiences" />
        </div>
        <!-- age completed -->
        <div class="completed">
            <div class="single-completed-wrapper">
                <div class="completed-title">
                    AGE
                </div>
                <div
                    v-for="(channel, index) in selectedChannels"
                    :key="index"
                    class="single-completed">
                    <div class="icon">
                        <icon
                            :name="channel === 'facebook' ? 'meta' : channel"
                            color="#8F9EA6"
                            size="25" />
                    </div>
                    <span class="single-name">{{ audienceStepData[channel].ages.min.name }} - {{ audienceStepData[channel].ages.max.name }}</span>
                </div>
            </div>
            <edit-button step="audiences" />
        </div>
        <!-- audience completed -->
        <div
            v-if="hasAudiences"
            class="completed">
            <div class="single-completed-wrapper">
                <div class="completed-title">
                    AUDIENCE
                </div>
                <div
                    v-for="(channel, index) in selectedChannels"
                    :key="index"
                    class="single-completed">
                    <div class="icon">
                        <icon
                            :name="channel === 'facebook' ? 'meta' : channel"
                            color="#8F9EA6"
                            size="25" />
                    </div>
                    <div class="single-name block">
                        ({{ audienceStepData[channel].existing_audiences.length }})
                    </div>
                </div>
            </div>
            <edit-button step="audiences" />
        </div>
    </div>
</template>

<script>
import EditButton from '../../components/EditButton';
import Icon from '@/components/globals/Icon';
import { mapState } from 'vuex';

export default {
    components: {
        Icon,
        EditButton
    },
    computed: {
        ...mapState({
            tikTokAgesInStore: state => state.adDeployment.audience.platform_specific.tiktok.ages,
            audienceStepData: state => state.adDeployment.audience.platform_specific,
            selectedChannels: state => state.adDeployment.platforms
        }),
        hasAudiences() {
            return Object.values(this.audienceStepData)
                .some((values) => {
                    return Boolean(values.existing_audiences?.length);
                });
        },
    }
};
</script>

<style lang="scss" scoped>
.completed{
    align-items: center;
    margin-bottom: 30px;
}
.single-completed-wrapper{
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;
    display: inline-flex;
    position: relative;
    padding: 50px 20px 20px 20px;
    min-width: 100px;
}
.single-completed{
    justify-content: space-around;
    margin-right: 30px;
}
.single-name{
    color: #03A2EA;
    font-style: italic;
    display: inline-block;
    margin-top: 5px;
    &.block{
        display: block;
    }
}
.completed-title{
    margin-bottom: 15px;
    font-weight: 700;
    position: absolute;
    top: 15px;
    left: 15px;
}
</style>