<template>
    <div class="single-age">
        <div class="single-icon">
            <icon
                :name="platform === 'facebook' ? 'meta' : platform"
                color="#8F9EA6"
                size="25" />
        </div>
        <div class="fields">
            <div class="field">
                <v-select
                    v-model="minimum"
                    return-object
                    :items="ages"
                    :disabled="disabled"
                    item-text="name"
                    item-value="value"
                    label="MINIMUM"
                    class="styled-field mb-3" />
            </div>
            <div class="field">
                <v-select
                    v-model="maximum"
                    return-object
                    :items="ages"
                    :disabled="disabled"
                    item-text="name"
                    item-value="value"
                    label="MAXIMUM"
                    class="styled-field mb-3" />
            </div>
        </div>
        <p
            v-if="platform === PLATFORM_TIKTOK"
            class="credit-campaign-text">
            TikTok uses age range groups. The age range groups that will be used in this deployment are: {{ selectedTikTokAgeGroups.join(', ') }}.
        </p>
        <p
            v-if="platform === PLATFORM_PINTEREST"
            class="credit-campaign-text">
            Pinterest uses age range groups. The age range groups that will be used in this deployment are: {{ selectedPinterestAgeGroups.join(', ') }}.
        </p>
        <p
            v-if="isCreditCampaign"
            class="credit-campaign-text">
            Credit Ads are not eligible to be customized by Age
        </p>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { TIKTOK_AGES_GROUPS, PINTEREST_AGES_GROUPS } from '@/components/ad-deployment/store/constants.js';
import { mapState } from 'vuex';
import { PLATFORM_PINTEREST, PLATFORM_FACEBOOK, PLATFORM_TIKTOK } from '@/helpers/globals';
export default {
    components: {
        Icon
    },
    props: {
        platform: {
            type: String,
            required: true
        },
        platformMin: {
            type: Object,
            required: true
        },
        platformMax: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            ages: [],
            minIndex: null,
            PLATFORM_PINTEREST,
            PLATFORM_FACEBOOK,
            PLATFORM_TIKTOK
        };
    },
    computed: {
        ...mapState({
            adDeployment: state => state.adDeployment
        }),
        agesInStore() {
            return this.adDeployment.audience.platform_specific[this.platform].ages;
        },
        disabled() {
            if (this.isCreditCampaign) {
                return true;
            }

            return false;
        },
        isCreditCampaign() { // refactor this into a prop if more platforms start using it
            if (this.platform === PLATFORM_FACEBOOK) {
                return this.adDeployment.campaign_selection.all_platforms.ad_category === 'credit';
            }

            return false;
        },
        selectedTikTokAgeGroups() {
            return this.filterTikTokAgeGroups(this.minimum.value, this.maximum.value).map(ageGroup => ageGroup.name);
        },
        selectedPinterestAgeGroups() {
            return this.filterPinterestAgeGroups(this.minimum.value, this.maximum.value).map(ageGroup => ageGroup.name);
        },
        maximum: {
            get() {
                return this.agesInStore.max;
            },
            set(value) {
                this.setMaxAge(value);
            }
        },
        minimum: {
            get() {
                return this.agesInStore.min;
            },
            set(value) {
                this.setMinAge(value);
            }
        }
    },
    mounted() {
        this.populateSelects();
    },
    methods: {
        populateSelects() {
            // loop over all the age ranges except the last one
            for (let i = this.platformMin.value; i <= this.platformMax.value - 1; i++) {
                this.ages.push({
                    name: i,
                    value: i
                });
            }
            this.ages.push({ // add the last age range which is a string like 65+
                name: this.platformMax.name,
                value: this.platformMax.value
            });
        },
        setMinAge(minimum) {
            // If the minimum is greater than the maximum, set the minimum to the maximum
            if (minimum.value > this.maximum.value) {
                // Find the index of the minimum in the ages object
                this.minIndex = this.ages.findIndex(age => age.value === minimum.value);
                // Check if the min index is the last item in the array
                this.minIndex = this.minIndex === this.ages.length - 1 ? this.minIndex : this.minIndex + 1;
                // Set the minimum to the age before the maximum
                this.maximum = this.ages[this.minIndex];
            }
            this.saveToStore({
                min: minimum,
                max: this.maximum
            });
        },
        setMaxAge(maximum) {
            // If the maximum is less than the minimum, set the maximum to the minimum
            if (maximum.value < this.minimum.value) {
                // Find the index of the maximum in the ages object
                this.maxIndex = this.ages.findIndex(age => age.value === maximum.value);
                // Check if we are at the index of 1 and don't allow it to go any lower
                this.maxIndex = this.maxIndex === 0 ? 0 : this.maxIndex - 1;
                // Set the maximum to the age after the minimum
                this.minimum = this.ages[this.maxIndex];
            }
            this.saveToStore({
                min: this.minimum,
                max: maximum
            });
        },
        filterTikTokAgeGroups(minimum, maximum) {
            return TIKTOK_AGES_GROUPS.filter(ageGroup => {
                return minimum <= ageGroup.max && maximum >= ageGroup.min;
            });
        },
        filterPinterestAgeGroups(minimum, maximum) {
            return PINTEREST_AGES_GROUPS.filter(ageGroup => {
                return minimum <= ageGroup.max && maximum >= ageGroup.min;
            });
        },
        saveToStore(agesData) {
            this.$store.commit('adDeployment/setPlatformAges', {
                ages: agesData,
                platform: this.platform
            });
        }
    },
};
</script>


<style lang="scss" scoped>
.single-age{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.single-icon{
    margin-right: 30px;
    margin-bottom: 15px;
}
.single-radio{
    margin-right: 30px;
}
.fields{
    display: flex;
}
.field{
    max-width: 115px;
    margin-right: 30px;
}
.options{
    border: 1px solid #DFDFE0;
    padding: 0 15px;
    max-width: 420px;
    margin-bottom: 15px;
    display: flex;
    flex-flow: row wrap;
}
.options > div{
    width: 33%;
}
.credit-campaign-text {
  font-size: 12px;
  color: #9B9B9B;
  width: 100%;
  font-style: italic;
}
</style>
<style>
.options > div.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot{
    margin-bottom: 0;
}
</style>
