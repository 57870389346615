<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        title="How would you like to refine this audience?"
        :help-link="helpLink"
        description="
            Select the types of audience customizations you would
            like to make and choose the appropriate configurations
            for each:
        ">
        <complete v-if="!active" />
        <template v-else>
            <!-- gender -->
            <gender />
            <!-- age -->
            <age-selections />
            <!-- existing audiences -->
            <existing-audiences />

            <div class="button-sad-holder mb-4">
                <styled-button
                    small
                    :disabled="!valid"
                    @click="goToNextStep">
                    <span>Continue</span>
                </styled-button>
            </div>
        </template>
    </step-wrapper>
</template>

<script>
import StepWrapper from '../../components/StepWrapper';
import StyledButton from '@/components/globals/StyledButton';
import Gender from '@/components/ad-deployment/steps/Audiences/Gender/index';
import ExistingAudiences from '@/components/ad-deployment/steps/Audiences/ExistingAudiences/index';
import AgeSelections from '@/components/ad-deployment/steps/Audiences/AgeSelections/index';
import Complete from './Complete';
import stepProps from '../stepProps';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'Audiences',
    components: {
        StepWrapper,
        StyledButton,
        Gender,
        ExistingAudiences,
        AgeSelections,
        Complete
    },
    props: stepProps,
    data() {
        return {
            helpLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#custom-refine-audience',
        };
    },
    computed: {
        ...mapState({
            selectedPreset: state => state.adDeployment.selectedPreset,
            currentStep: state => state.adDeployment.current_step,
        })
    },
    created() {
        if (this.selectedPreset.title != 'Custom' && this.currentStep === this.index) {
            this.goToNextStep();
        }
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        })
    },
};
</script>

<style lang="scss" scoped>
</style>